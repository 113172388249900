define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j4WYrn61",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"select\",\"selected\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"selectedItemComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"selectedItemComponent\"]]],[[\"extra\",\"option\",\"select\"],[[29,\"readonly\",[[25,[\"extra\"]]],null],[29,\"readonly\",[[25,[\"select\",\"selected\"]]],null],[29,\"readonly\",[[25,[\"select\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"ember-power-select-selected-item\"],[9],[15,1,[[25,[\"select\",\"selected\"]],[25,[\"select\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[29,\"and\",[[25,[\"allowClear\"]],[29,\"not\",[[25,[\"select\",\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"ember-power-select-clear-btn\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],\"clear\"],null]],[12,\"ontouchstart\",[29,\"action\",[[24,0,[]],\"clear\"],null]],[9],[0,\"×\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[25,[\"placeholderComponent\"]]],[[\"placeholder\"],[[25,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\"],[11,\"class\",\"ember-power-select-status-icon\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });

  _exports.default = _default;
});