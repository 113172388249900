define("ember-power-select/templates/components/power-select/placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fK9KUBf7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"placeholder\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"ember-power-select-placeholder\"],[9],[1,[23,\"placeholder\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/placeholder.hbs"
    }
  });

  _exports.default = _default;
});